import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import styled from '@emotion/styled';

import { documentsQueries } from 'api';
import { APIDocument, DocumentFileType } from 'types';

import { DocumentCommentItems } from './DocumentCommentItems';

const CustomContainer = styled.div`
  padding: 16px 32px;
`;

interface DocumentClientCommentsProps {
  document: APIDocument;
}

const CONTAINER_HEIGHT = 400;
const PAGE_SIZE = 10;
const ITEM_COUNT = 4;

export const DocumentClientComments = ({ document }: DocumentClientCommentsProps) => {
  const { t } = useTranslation();

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: documentsQueries.getComments(document.id).queryKey,
    queryFn: documentsQueries.getComments(document.id, { per_page: PAGE_SIZE }).queryFn,
    getNextPageParam: (lastPage) =>
      lastPage.current_page < lastPage.total_pages ? lastPage.current_page + 1 : undefined
  });

  if (document.file_type !== DocumentFileType.PDF) return null;

  const count = data?.pages[0].count;

  const handleScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === CONTAINER_HEIGHT) {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  };

  return (
    <Spin spinning={isLoading}>
      <CustomContainer>
        <div>
          {t('comments')} <span>({count})</span>
        </div>

        <DocumentCommentItems
          document={document}
          comments={data?.pages.flatMap((page) => page.results) || []}
          listProps={
            count && count >= ITEM_COUNT
              ? { itemHeight: 0, height: CONTAINER_HEIGHT, onScroll: handleScroll }
              : {}
          }
        />
      </CustomContainer>
    </Spin>
  );
};
