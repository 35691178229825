import { Locale } from 'utils';
import { IzitInvoiceCategoryType } from './documents.types';

export enum UserStatuses {
  active = 'active',
  pending = 'pending',
  inactive = 'inactive',
  deleted = 'deleted'
}

export enum UserType {
  admin = 'admin',
  client = 'client',
  partner = 'partner'
}

export type ActiveUserRole = {
  isAdmin: boolean;
  isClient: boolean;
  isPartner: boolean;
};

export interface UserProfileType extends FormData {
  language: Locale;
  name: string;
  color: string;
  auto_send_document_types: IzitInvoiceCategoryType;
}
