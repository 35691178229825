/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import VirtualList, { ListProps } from 'rc-virtual-list';
import { AxiosError } from 'axios';
import { documents, documentsQueries } from 'api';
import { ThemeContext } from 'contexts';
import { Campaigns } from 'icons';
import { dateSpecialFormat, formErrorHandler, format, timeSpecialFormat } from 'utils';
import { APIDocument, DocumentComment, DocumentCommentSource } from 'types';
import { stylesInit } from './DocumentComments.styles';

interface DocumentCommentItemProps {
  comments: DocumentComment[];
  document: APIDocument;
  listProps?: Omit<ListProps<DocumentComment>, 'children' | 'data' | 'itemKey'>;
}

export const DocumentCommentItems = ({
  comments,
  document,
  listProps
}: DocumentCommentItemProps) => {
  const { currentTheme } = useContext(ThemeContext);
  const styles = stylesInit(currentTheme);
  const queryClient = useQueryClient();
  const [form] = useForm();

  const { mutate } = useMutation(documents.postComment, {
    onSuccess: (res) => {
      form.resetFields();
      queryClient.invalidateQueries(documentsQueries.getById(document.id).queryKey);
      queryClient.invalidateQueries(documentsQueries.getComments(document.id).queryKey);
    },
    onError: (error: AxiosError) => {
      form.setFields(formErrorHandler(error));
    }
  });

  const onSubmit = (value: { text: string }) => {
    mutate({
      ...value,
      thread_id: comments[comments.length - 1]?.thread_id,
      documentId: document.id,
      response: comments[comments.length - 1]?.response
    });
  };

  return (
    <div>
      <VirtualList data={comments} itemKey="comments" {...listProps}>
        {(comment, key) => (
          <div css={styles.CommentItem} key={key}>
            <div css={styles.CommentItemAuthor}>
              <span>
                {comment.source === DocumentCommentSource.APOLLO
                  ? comment.source
                  : comment.user?.name}
              </span>{' '}
              raspuns
            </div>
            <div css={styles.CommentItemText}>{comment.text}</div>
            <div css={styles.CommentItemDate}>
              {format(comment.created_at, dateSpecialFormat)} ora{' '}
              {format(comment.created_at, timeSpecialFormat)}
            </div>
          </div>
        )}
      </VirtualList>
      <Form form={form} css={styles.CommentItemForm} onFinish={onSubmit}>
        <Form.Item
          name="text"
          extra={
            <Button
              css={styles.CommentItemFormButton}
              type="ghost"
              size="small"
              htmlType="submit"
              icon={<Campaigns />}
            />
          }
          rules={[
            {
              required: true,
              message: 'Te rugam introdu un mesaj!'
            }
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} placeholder="Mesajul tau" />
        </Form.Item>
      </Form>
    </div>
  );
};
