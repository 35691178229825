export const us = {
  documentName: 'Document name',
  folder: 'Folder',
  uploadDate: 'Upload date',
  documentState: 'Document status',
  noPartnerDocuments: 'Currently, none of your partners <br /> have uploaded a document.',
  searchDocuments: 'Search documents',
  evolutionGraph: 'Evolution chart',
  last12Months: 'Last 12 months',
  last6Months: 'Last 6 months',
  last3Months: 'Last 3 months',
  lastMonth: 'Last month',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  from: 'From',
  to: 'To',
  documents: 'Documents',
  partners: 'Partners',
  clients: 'Customers',
  yourProfile: 'Your Profile',
  changePassword: 'Change Password',
  logOut: 'Log Out',
  legend: 'Legend',
  documentIsUploading: 'The document is uploading or being sent.',
  documentWasUploaded: 'The document has been uploaded to the Portal.',
  documentIsProcessing: 'The document is processing and is locked for other actions.',
  documentProcessed: 'The document has been processed.',
  documentNeedInfoDescription: 'The document needs clarification.',
  documentNeedReplacementDescription: 'The document needs to be replaced.',
  documentNotNeeded: 'The document is not needed by the customer.',
  documentCantBePassed: "The document couldn't be transmitted to Apollo.",
  partnerActiveDescription:
    'Your partner is active with all document management permissions in the Portal.',
  partnerPendingDescription:
    'Your partner is active with all document management permissions in the Portal.',
  partnerInactiveDescription: 'Currently, your partner cannot upload documents to the Portal.',
  partnerDeletedDescription:
    'Your partner can no longer access the Portal, but you can still view the uploaded documents.',
  clientActiveDescription:
    'Your customer is active with all document management permissions in the Portal.',
  clientPendingDescription:
    'Your customer is active with all document management permissions in the Portal.',
  clientInactiveDescription: 'Currently, your customer cannot add partners.',
  clientDeletedDescription: 'Your customer can no longer access the Portal.',
  total: 'All',
  totalLabel: 'All documents',
  pending: 'Uploading',
  pendingLabel: 'Documents in the uploading process',
  uploaded: 'Uploaded',
  uploadedLabel: 'Uploaded documents',
  processing: 'Processing',
  processingLabel: 'Documents in processing',
  needReplacement: 'Replacement',
  needReplacementLabel: 'Documents to be replaced',
  needInfo: 'Request Details',
  needInfoLabel: 'Documents with requested details',
  done: 'Processed',
  doneLabel: 'Processed documents',
  failed: 'Failed',
  failedLabel: 'Failed documents',
  deleted: 'Deleted',
  deletedLabel: 'Deleted documents',
  rejected: 'Rejected',
  rejectedLabel: 'Rejected documents',
  all: 'All',
  allPartners: 'All partners',
  active: 'Active',
  activePartnersLabel: 'Active partners',
  inactive: 'Inactive',
  inactivePartnersLabel: 'Inactive partners',
  pendingUser: 'Pending',
  pendingPartnersLabel: 'Pending partners',
  deletedUser: 'Deleted',
  deletedPartnersLabel: 'Deleted partners',
  allClients: 'All customers',
  activeClientsLabel: 'Active customers',
  inactiveClientsLabel: 'Inactive customers',
  pendingClientLabel: 'Customers in pending',
  deletedClientsLabel: 'Deleted customers',
  documentPending: 'Uploading',
  documentUploaded: 'Uploaded',
  documentProcessing: 'In processing',
  documentDone: 'Processed',
  documentNeedReplacement: 'Replacement',
  documentNeedInfo: 'Request Details',
  documentFailed: 'Failed',
  documentDeleted: 'Deleted',
  documentRejected: 'Rejected',
  documentMerged: 'Merged',
  documentSplit: 'Split',
  documentTotal: 'Total',
  replace: 'Replace',
  delete: 'Delete',
  noUploadedDocument: "Currently, you haven't uploaded any documents.",
  deleteDocuments: 'Delete documents',
  deleteDocument: 'Delete document',
  approveDocumentDelete: 'Are you sure you want to delete the document?',
  approveDocumentBulkDelete: 'Are you sure you want to delete the selected {{length}} documents?',
  sendDocuments: 'Send documents',
  rejectDocument: 'Document rejected',
  document: 'Document',
  add: 'Add',
  resultsPerPage: 'results per page',
  cantMarkNotificationAsRead: "We can't mark the notification as read right now.",
  notifications: 'Notifications',
  makeClickToMarkAsRead: 'Click to mark as read',
  resetEmailWasSent: 'The reset email has been sent.',
  cancel: 'Cancel',
  change: 'Change',
  changePasswordQuestion:
    'Are you sure you want to change your password? If yes, you will receive a confirmation email to set a new password.',
  myProfile: 'Your Account',
  requestChangePassword: 'Request Password Change',
  partnerDetails: 'Partner Details',
  companyCif: 'Company CIF',
  department: 'Department',
  emailAddress: 'Email Address',
  workspace: 'Workspace',
  activity: 'Activity',
  profileStatus: 'Account Status',
  profileCreatedAt: 'Account Created On',
  lastActivity: 'Last Activity',
  storageSpace: 'Storage Space (GB)',
  partnerName: 'Partner Name',
  edit: 'Edit',
  deletedPartner: 'Deleted Partner',
  deletedPartners: 'Deleted Partners',
  deletePartner: 'Delete Partner',
  partnersBulkDeleteQuestion: 'Are you sure you want to delete {{count}} partners?',
  noPartnersDescription:
    'Currently, you have no partners in the list! <br /> Start by manually adding or importing a list.',
  searchPartner: 'Search Partner',
  partnerSuccessfullyAdded: 'The partners have been successfully added.',
  uploadList: 'Upload List',
  example: 'Example',
  manual: 'Manual',
  profileAccess: 'Account Access',
  pleaseInsertEmail: 'Please insert an email!',
  pleaseInsertPassword: 'Please insert a password!',
  password: 'Password',
  keepMeLogged: 'Keep me logged in',
  forgotPasswordQuestion: 'Forgot Password?',
  logIn: 'Log In',
  portalDescription1:
    'A platform that simplifies the process of collecting, verifying, and uploading documents to be intelligently processed by Apollo.',
  portalDescription2: 'Increased efficiency and productivity.',
  portalDescription3: 'Efficiency & productivity.',
  portalDescription4: 'Real-time visibility.',
  portalDescription5: 'Over uploaded documents and validation rules before intelligent processing.',
  portalDescription6: '100% control.',
  portalDescription7: 'Over uploaded documents and validation rules before intelligent processing.',
  notFoundPageTitle: 'Lost Page!',
  notFoundPageDescription:
    "We're sorry, but the page you're looking for doesn't exist or has been moved.",
  backToDashboard: 'Back to Dashboard',
  resetPassword: 'Reset Password!',
  pleaseInputEmail: 'Please input your email.',
  receiveEmail: 'Receive Email',
  inputEmail: 'Input Email',
  clientName: 'Customer Name',
  clientDetails: 'Customer Details',
  documentsNumber: 'Number of Documents',
  activeUser: 'Active',
  inactiveUser: 'Inactive',
  deletedUserLabel: 'Deleted',
  totalUser: 'Total',
  editClient: 'Edit Customer',
  addClient: 'Add Customer',
  save: 'Save',
  clientEditDescription:
    "Once you've saved the changes, your customer will receive a notification that their data has been updated.",
  clientAddDescription:
    "Once you've saved the customer, they will receive an email containing their access details.",
  companyName: 'Company Name',
  cif: 'CIF',
  emailAddressLabel: 'Email Address (Login User)',
  apolloApiKey: 'Apollo API Key',
  domainPrefix: 'Domain Prefix',
  wrongFormat: 'Wrong Format',
  clientRequestedPassword: 'The customer has requested a password change',
  changePasswordDescription:
    "Are you sure you want to change the customer's password? If yes, the customer will receive a confirmation email to set a new password.",
  apolloId: 'Apollo ID',
  createProfile: 'Create Account',
  deletedClient: 'Deleted customer',
  deletedClients: 'Deleted Customers',
  searchClient: 'Search customer',
  noClientDescription:
    'Currently, you have no customers in the list! <br /> Start by manually adding or importing a list.',
  deleteClientQuestion: 'Are you sure you want to delete the customer?',
  deleteClient: 'Delete customer',
  deleteClients: 'Delete customers',
  bulkDeleteClientQuestion: 'Are you sure you want to delete {{count}} customers?',
  addedClient: 'Customer Added',
  editedClient: 'Customer Edited',
  editPartner: 'Edit Partner',
  addPartner: 'Add Partner',
  partnerEditDescription:
    "Once you've saved the changes, your partner will receive a notification that their data has been updated.",
  partnerAddDescription:
    "Once you've saved the partner, they will receive an email containing their access details.",
  partnerEmailAddress: 'Email Address (Partner)',
  savePassword: 'Save Password',
  thisFieldIsRequired: 'This field is required',
  newValueDoesNotMatch: 'The new value does not match the previous field!',
  pleaseSetYourPassword: 'Please set your password.',
  welcome: 'Welcome!',
  repeatPassword: 'Repeat Password',
  aSmallLetter: 'A small letter',
  aBigLetter: 'A capital letter',
  aNumber: 'A number',
  aSymbol: 'A special character',
  min8Characters: 'Minimum 8 characters',
  downloadFolder: 'Download Folder',
  'ro-ro': 'Romanian',
  'ro-us': 'English',
  'us-us': 'English',
  'us-ro': 'Romanian',
  download: 'Download',
  upload: 'Upload',
  partnerDeleteQuestion: 'Are you sure you want to delete the partner?',
  editedPartner: 'Partner Edited',
  addedPartner: 'Partner Added',
  documentsAppearAfterProcessing: 'Files will appear in the list once they have been processed.',
  enrollEFactura: 'Enroll to e-Factura',
  eFacturaSuccessfulEnrollment: 'You have been successfully enrolled in e-Factura',
  eFacturaFailedEnrollment: 'Enrollment in e-Factura has failed',
  eFacturaFailedEnrollmentNotLoggedIn:
    'Enrollment in e-Factura has failed, you are not logged in to the Portal to continue the operation',
  clientPassword: 'Client password',
  sentDocuments: 'Documents sent ({{count}})',
  syncEFactura: 'Sync with e-Factura',
  syncEFacturaSuccessful: 'Synchronization with e-Factura was successful',
  syncEFacturaError: 'Synchronization with e-Factura failed',
  lastEnrollment: 'Last Enrollment',
  status: 'Status',
  evolution: 'Evolution',
  accessLevels: 'Access levels',
  name: 'Name',
  partner: 'Partner',
  lists: 'Lists',
  created: 'Created',
  search: 'Search',
  selectedElement: '{{count}} selected {{name}}',
  selectedElements: '{{count}} selected {{name}}',
  accessLevel: 'Access level',
  users: 'Users',
  addAccessLevel: 'Add access level',
  editAccessLevel: 'Edit access level',
  workspaceTagName: 'Workspace tag name',
  workspaceDescription: 'Workspace description',
  deleteAccessLevel: 'Delete access level',
  accessLevelDeleteQuestion: 'Are you sure you want to delete the access level?',
  deletedAccessLevel: 'Deleted access level',
  loadMore: 'Load more',
  editedAccessLevel: 'Access Level Edited',
  addedAccessLevel: 'Access Level Added',
  editUser: 'Edit user',
  addUser: 'Add user',
  editedAccessLevelGroup: 'Access Level Group Edited',
  addedAccessLevelGroup: 'Access Level Group Added',
  addAccessLevelGroup: 'Add access level group',
  editAccessLevelGroup: 'Edit access level group',
  deletedAccessLevelGroup: 'Deleted access level group',
  accessLevelGroupDeleteQuestion: 'Are you sure you want to delete the access level group?',
  deleteAccessLevelGroup: 'Delete access level group',
  noAccessLevelsDescription: 'To start seeing some action, please add your first access level.',
  access: 'Access',
  pendingApproval: 'Pending Approval',
  pendingApprovalLabel: 'Documents pending approval',
  nextFile: 'Next file',
  reject: 'Reject',
  approve: 'Approve',
  moveFile: 'Move file',
  move: 'Move',
  addFolder: 'Add folder',
  fromFolder: 'From folder:',
  toFolder: 'To folder:',
  addNewFolderPlaceholder: 'Add new folder',
  searchInputPlaceholder: 'Search folder',
  movedToFolderNotification: 'The file has been successfully moved to {{folder}}',
  moveMultipleFilesTitle: 'Move {{count}} selected',
  selectPartnerMessage: 'To continue, please select a partner.',
  cannotMoveReadonlyMessage:
    'Please remove any read only files from the selection before proceeding.',
  folderCreateNotification: 'The folder has been successfully created',
  approveFolderDelete: 'Are you sure you want to delete the folder?',
  deleteFolder: 'Delete folder',
  permanentlyDeletedAdminFolder:
    "This folder will be permanently deleted from this account and the partner's account. Are you sure you want to delete?",
  permanentlyDeletedFolder:
    "This folder will be permanently deleted from this account and the partner's account. Are you sure you want to delete?",
  delegateApproval: 'Delegate Approval',
  delegateApprovalLabel: 'Documents delegate approval',
  delegate: 'Delegate',
  cancelDelegate: 'Cancel delegate',
  delegateToOther: 'Delegate to other',
  delegated: 'Delegated',
  confirm: 'Confirm',
  waiting: 'Waiting',
  approved: 'Approved',
  expired: 'Expired',
  delegateNotification: 'The document was successfully delegated',
  delegateCancelNotification: 'The document delegation has been successfully canceled',
  interestingFolderName: 'Interesting folder name',
  folders: 'Folders',
  thisIsDocument: 'This is a document ',
  typeFolderName: 'Type folder name',
  clearAll: 'Clear all',
  selectAll: 'Select all {{count}}',
  partnersCount: 'Partners Count',
  reloadBrowser: 'Session ended, to start a new session, please close and reopen your browser',
  documentIsPending: 'The document is awaiting to be reviewed',
  documentWasDelegated: 'The document has been delegated for review',
  myPartnerProfile: 'My profile',
  myClientProfile: 'Admin profile',
  archive: 'Archive',
  storageLimitAlert: "You've used over 80% of your SmartPortal storage.",
  mentenanteMessage:
    'We remind you that following changes made in the SPV, it is necessary to re-authorize (enroll) your token for login',
  totalDownloadedDocuments: '{{downloaded}} documents prepared from {{total}}',
  downloadProgress: 'Download progress',
  selectCategory: 'Select category',
  selectIzitCategoryInvoice: 'The categories of invoices that are sent to Izit',
  others: 'Others',
  received: 'Received',
  issued: 'Issued',
  wrongDocumentType:
    'You can only approve documents in PDF format with a (Pending Approval) status.',
  comments: 'Comments'
};
