/** @jsxImportSource @emotion/react */

import { Divider, Space } from 'antd';
import { Text } from '../Typography';
import { Put } from '../Put';
import { ReactElement, ReactNode, useContext } from 'react';
import { ThemeContext } from '../../contexts';
import { stylesInit } from './DetailsCard.styles';
import { useTranslation } from 'react-i18next';

interface DetailsCardInterface {
  title: string;
  Icon: any;
  Avatar?: ReactNode;
  items: { label: string; value?: number | string | ReactElement }[];
}

export const DetailsCard = ({ items, title, Icon, Avatar }: DetailsCardInterface) => {
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);

  const styles = stylesInit(currentTheme);

  return (
    <div style={{ width: '100%' }}>
      <Space css={styles.CardTitle} styles={{ item: { display: 'flex' } }}>
        <Text size="lg" strong>
          {t(title)}
        </Text>

        {Avatar ? Avatar : <Icon fontSize={20} fill={currentTheme['colorPrimary']} />}
      </Space>

      {items.map((i) => (
        <div key={i.label}>
          <div style={{ padding: '16px 32px' }}>
            <div>
              <Text size="sm" style={{ color: currentTheme['grey-50'] }}>
                {i.label}
              </Text>
            </div>

            <Text size="lg">
              <Put>{i.value}</Put>
            </Text>
          </div>

          <Divider css={styles.Divider} />
        </div>
      ))}
    </div>
  );
};
